import React from 'react';
import Pages from '../components/Pages';
import ContactUs from '../components/pages/ContactUs';
import { graphql } from 'gatsby';

const ContactUsPage = () => {
  return (
    <Pages title="FOLK - Contact Us">
      <ContactUs />
    </Pages>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ContactUsPage;
