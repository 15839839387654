import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import emailjs from '@emailjs/browser';
import CustomContainer from 'components/CustomContainer';
import CircularProgress from 'components/animation/CircularProgress';
import IMG_BANNER from 'images/black-bg.png';
import { useTranslation } from 'react-i18next';

const CustomInput = ({ inputData, placeholder, name, type, handleChange }) => {
  return (
    <input
      defaultValue={inputData[name]}
      required
      pattern={type === 'tel' && '[0-9]{1,}'}
      name={name}
      type={type}
      placeholder={placeholder}
      onChange={(e) => handleChange(e, name)}
      className="border-b bg-transparent w-full lg:w-[75%] text-[13px] lg:text-sm focus:outline-none placeholder:text-sm py-2"
    />
  );
};

const ContactUs = () => {
  const form = React.useRef();
  const [inputData, setInputData] = React.useState({
    full_name: '',
    company_name: '',
    email_address: '',
    phone_number: '',
    message: '',
  });
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isSubmit, setIsSubmit] = React.useState(false);

  const handleChange = (event, inputType) => {
    setInputData({ ...inputData, [inputType]: event.target.value });
  };

  const checkInput = React.useCallback(() => {
    if (
      inputData.full_name.length &&
      inputData.company_name.length &&
      inputData.email_address.length &&
      inputData.phone_number.length &&
      inputData.message.length
    ) {
      setIsDisabled(false);
    }
  }, [inputData]);

  useEffect(() => checkInput(), [checkInput]);

  const sendEmail = async (e) => {
    try {
      e.preventDefault();
      setIsDisabled(true);
      setIsSubmit(true);
      await emailjs.sendForm(
        process.env.EMAILJS_SERVICE_ID,
        process.env.EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.EMAILJS_PUBLIC_KEY,
      );
      form.current.reset();
      setInputData({
        full_name: '',
        company_name: '',
        email_address: '',
        phone_number: '',
        message: '',
      });
      setIsSubmit(false);
    } catch (error) {
      console.log(error.text);
    }
  };

  const { t } = useTranslation('homepage');

  return (
    <div
      className="contact-us min-h-[84.5vh] bg-black text-white pt-16"
      style={{
        backgroundImage: `url(${IMG_BANNER})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className="pt-20 sm:pt-32">
        <CustomContainer className="flex flex-col lg:flex-row justify-between w-full">
          <div className="flex-1">
            <p className="text-3xl lg:text-6xl font-semibold mb-7 sm:w-[70%] lg:w-auto">
              {t('contactUs')}
            </p>
            <p className="text-sm sm:text-base font-light lg:w-[50%]">
              We are proud to have grown together with these extra-mile walkers
              in our family.
            </p>
          </div>
          <form
            ref={form}
            onSubmit={sendEmail}
            className="flex-1 flex flex-col items-start lg:items-end mt-10 lg:mt-0"
          >
            <div className="space-y-4 flex flex-col items-end w-full">
              <CustomInput
                handleChange={handleChange}
                inputData={inputData}
                placeholder="Full Name"
                name="full_name"
                type="text"
              />
              <CustomInput
                handleChange={handleChange}
                inputData={inputData}
                placeholder="Company Name"
                name="company_name"
                type="text"
              />
              <CustomInput
                handleChange={handleChange}
                inputData={inputData}
                placeholder="Phone Number"
                name="phone_number"
                type="tel"
              />
              <CustomInput
                handleChange={handleChange}
                inputData={inputData}
                placeholder="Email Address"
                name="email_address"
                type="email"
              />
              <CustomInput
                handleChange={handleChange}
                inputData={inputData}
                placeholder="Message"
                name="message"
                type="text"
              />
            </div>
            <div className="text-[11px] lg:text-[13px] font-thin flex justify-end w-full lg:w-[75%] mb-8 mt-2">
              {inputData.message.length}/1000 Characters
            </div>
            <button
              disabled={isDisabled}
              type="submit"
              className="w-full lg:w-[75%] py-3 text-sm font-thin bg-[#FF4C00] disabled:bg-[#a37a68] disabled:text-slate-500 disabled:shadow-none"
              style={{ borderRadius: '20px' }}
            >
              <div className="flex justify-center items-center">
                {isSubmit && <CircularProgress />}
                <span style={{ color: '#fff' }}>Send</span>
              </div>
            </button>
          </form>
        </CustomContainer>
        <div className="mt-20 lg:mt-36">
          <CustomContainer className="pb-[8rem]">
            <div className="flex items-start flex-col">
              <p className="text-base font-light w-[35%] hidden lg:block">
                Prosperity Tower, Jl. Jend. Sudirman Kav 52-53 No. 5, RW 3,
                Senayan, Kec. Kebayoran Baru, South Jakarta, DKI Jakarta, 12190
              </p>
              <p className="text-base font-light w-[35%] hidden lg:block mt-4">
                <b style={{ fontWeight: 600 }}>Phone:</b> 02150123124
              </p>
              <p className="text-base font-light w-[35%] hidden lg:block">
                <b style={{ fontWeight: 600 }}>Email:</b>{' '}
                info@folkgroup.co
              </p>
            </div>
          </CustomContainer>
        </div>
      </div>
    </div>
  );
};

CustomInput.propTypes = {
  placeholder: PropTypes.string,
};

export default ContactUs;
